@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'GTWalsheim';
  src: url('/fonts/GTWalsheim/GT-Walsheim-Medium.ttf');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'GTWalsheim';
  src: url('/fonts/GTWalsheim/GT-Walsheim-Regular.ttf');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'GTWalsheim';
  src: url('/fonts/GTWalsheim/GT-Walsheim-Light.ttf');
  font-weight: 200;
  font-display: swap;
}

/**

*/

.react-toast-notifications__container {
  z-index: 1000000 !important;
}

.sunsett-pie-chart {
  max-width: 300px;
  margin: 0 auto;
}

.sunsett-pie-chart .VictoryContainer > svg {
  width: 200% !important;
  height: 2% !important;
  margin-left: -50% !important;
  margin-bottom: -25% !important;
  margin-top: -20% !important;
}
.sunsett-pie-chart .VictoryContainer > div svg g[role='presentation'] {
  box-shadow: 2px 2px 2px 2px #00000020;
}

.sunsett-pie-chart
  .VictoryContainer
  > div
  svg
  g[role='presentation']
  text
  tspan {
  font-family: GTWalsheim, sans-serif !important;
}

.sunsett-pie-chart
  .VictoryContainer
  > div
  svg
  g[role='presentation']
  text
  tspan:first-child {
  font-weight: bold;
}

.flyout-tooltip {
  transform: translateX(5px);
  z-index: 10;
  position: relative;
}

.flyout-tooltip.pie {
  /* transform: translateY(-10px); */
}

.flyout-tooltip:before {
  content: '';
  height: 10px;
  width: 10px;
  transform: translateY(-50%) rotate(45deg);
  background: white;
  position: absolute;
  top: 50%;
  left: -2px;
  z-index: -1;
}

.flyout-tooltip.pie:before {
  /* top: unset;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg); */
  left: -8px;
  height: 15px;
  width: 15px;
}

.sunsett-line-chart svg {
  overflow: visible;
}

.react-date-picker__calendar {
  z-index: 40;
}
