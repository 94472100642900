.terms-of-use {
  p {
    line-height: 1.5 !important;
  }

  li {
    padding: 2px 0 !important;
    line-height: 1.5 !important;
  }
  ol.lst-kix_list_1-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-0 {
    list-style-type: none;
  }
  .lst-kix_list_1-4 > li {
    counter-increment: lst-ctn-kix_list_1-4;
  }
  ol.lst-kix_list_1-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-6.start {
    counter-reset: lst-ctn-kix_list_1-6 0;
  }
  .lst-kix_list_1-1 > li {
    counter-increment: lst-ctn-kix_list_1-1;
  }
  ol.lst-kix_list_1-3.start {
    counter-reset: lst-ctn-kix_list_1-3 0;
  }
  ol.lst-kix_list_1-2.start {
    counter-reset: lst-ctn-kix_list_1-2 0;
  }
  ol.lst-kix_list_1-8.start {
    counter-reset: lst-ctn-kix_list_1-8 0;
  }
  .lst-kix_list_1-0 > li:before {
    content: '' counter(lst-ctn-kix_list_1-0, decimal) '. ';
  }
  ol.lst-kix_list_1-5.start {
    counter-reset: lst-ctn-kix_list_1-5 0;
  }
  ol.lst-kix_list_1-7 {
    list-style-type: none;
  }
  .lst-kix_list_1-1 > li:before {
    content: '' counter(lst-ctn-kix_list_1-1, lower-latin) '. ';
  }
  .lst-kix_list_1-2 > li:before {
    content: '' counter(lst-ctn-kix_list_1-2, lower-roman) '. ';
  }
  .lst-kix_list_1-7 > li {
    counter-increment: lst-ctn-kix_list_1-7;
  }
  ol.lst-kix_list_1-8 {
    list-style-type: none;
  }
  .lst-kix_list_1-3 > li:before {
    content: '' counter(lst-ctn-kix_list_1-3, decimal) '. ';
  }
  .lst-kix_list_1-4 > li:before {
    content: '' counter(lst-ctn-kix_list_1-4, lower-latin) '. ';
  }
  ol.lst-kix_list_1-0.start {
    counter-reset: lst-ctn-kix_list_1-0 0;
  }
  .lst-kix_list_1-0 > li {
    counter-increment: lst-ctn-kix_list_1-0;
  }
  .lst-kix_list_1-6 > li {
    counter-increment: lst-ctn-kix_list_1-6;
  }
  .lst-kix_list_1-7 > li:before {
    content: '' counter(lst-ctn-kix_list_1-7, lower-latin) '. ';
  }
  .lst-kix_list_1-3 > li {
    counter-increment: lst-ctn-kix_list_1-3;
  }
  .lst-kix_list_1-5 > li:before {
    content: '' counter(lst-ctn-kix_list_1-5, lower-roman) '. ';
  }
  .lst-kix_list_1-6 > li:before {
    content: '' counter(lst-ctn-kix_list_1-6, decimal) '. ';
  }
  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }
  ol.lst-kix_list_1-7.start {
    counter-reset: lst-ctn-kix_list_1-7 0;
  }
  .lst-kix_list_1-2 > li {
    counter-increment: lst-ctn-kix_list_1-2;
  }
  .lst-kix_list_1-5 > li {
    counter-increment: lst-ctn-kix_list_1-5;
  }
  .lst-kix_list_1-8 > li {
    counter-increment: lst-ctn-kix_list_1-8;
  }
  ol.lst-kix_list_1-4.start {
    counter-reset: lst-ctn-kix_list_1-4 0;
  }
  .lst-kix_list_1-8 > li:before {
    content: '' counter(lst-ctn-kix_list_1-8, lower-roman) '. ';
  }
  ol.lst-kix_list_1-1.start {
    counter-reset: lst-ctn-kix_list_1-1 0;
  }
  ol {
    margin: 0;
    padding: 0;
  }
  table td,
  table th {
    padding: 0;
  }
  .c1 {
    -webkit-text-decoration-skip: none;
    color: #000000;
    font-weight: 700;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 1rem;
    font-style: normal;
  }
  .c0 {
    margin-left: 54pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c4 {
    margin-left: 18pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c3 {
    color: #000000;
    font-weight: 300;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 1rem;
    font-style: normal;
  }
  .c10 {
    color: #000000;
    font-weight: 300;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;

    font-style: normal;
  }
  .c2 {
    color: #000000;
    font-weight: 500;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 1rem;
    font-style: normal;
    // font-family: p22-mackinac-pro, serif;
    text-align: left;
  }
  .c5 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: justify;
    height: 12pt;
  }
  .c17 {
    color: #000000;
    font-weight: 300;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 1rem;

    font-style: normal;
  }
  .c16 {
    -webkit-text-decoration-skip: none;
    color: #000000;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-style: normal;
  }
  .c12 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c13 {
    -webkit-text-decoration-skip: none;
    color: #1155cc;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-style: normal;
  }
  .c11 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c6 {
    font-size: 1rem;
    font-weight: 300;
  }
  .c14 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt;
  }
  .c8 {
    color: inherit;
    text-decoration: inherit;
  }
  .c9 {
    padding: 0;
    margin: 0;
  }
  .c7 {
    margin-left: 18pt;
  }
  .c18 {
    font-style: italic;
  }
  .c15 {
    height: 12pt;
  }
  .c19 {
    margin-left: 36pt;
  }
  .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;

    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    line-height: 1;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  li {
    color: #000000;
    font-size: 12pt;
  }
  p {
    margin: 0;
    color: #000000;
    font-size: 12pt;
  }
  h1 {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 6pt;

    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h2 {
    padding-top: 18pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 4pt;

    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;

    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;

    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;

    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 1rem;
    padding-bottom: 2pt;

    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
}
