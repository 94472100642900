// @import url('https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLSGpxL_7AiMlXksihSwXLVVUY4CRT7JNLdKN7yejUsjr');
.privacy-policy {
  p {
    line-height: 1.5 !important;
  }

  li {
    padding: 2px 0 !important;
    line-height: 1.5 !important;
  }
  ul.lst-kix_list_1-0 {
    list-style-type: none;
  }
  .lst-kix_list_3-0 > li:before {
    content: '\0025cf  ';
    font-size: 8px;
    transform: translateY(-2px);
  }
  ul.lst-kix_list_5-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-8 {
    list-style-type: none;
  }
  .lst-kix_list_3-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_3-2 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_5-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-3 {
    list-style-type: none;
  }
  .lst-kix_list_3-5 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_5-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-1 {
    list-style-type: none;
  }
  .lst-kix_list_3-4 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_1-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-7 {
    list-style-type: none;
  }
  .lst-kix_list_3-3 > li:before {
    content: '\0025cf  ';
    font-size: 8px;
    transform: translateY(-2px);
  }
  ul.lst-kix_list_5-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-6 {
    list-style-type: none;
  }
  .lst-kix_list_3-8 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_3-6 > li:before {
    content: '\0025cf  ';
    font-size: 8px;
    transform: translateY(-2px);
  }
  .lst-kix_list_3-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_5-0 > li:before {
    content: '\0025cf  ';
    font-size: 8px;
    transform: translateY(-2px);
  }
  .lst-kix_list_4-8 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_5-3 > li:before {
    content: '\0025cf  ';
    font-size: 8px;
    transform: translateY(-2px);
  }
  .lst-kix_list_4-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_5-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_5-1 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_4-8 {
    list-style-type: none;
  }
  .lst-kix_list_5-7 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_4-6 {
    list-style-type: none;
  }
  .lst-kix_list_5-6 > li:before {
    content: '\0025cf  ';
    font-size: 8px;
    transform: translateY(-2px);
  }
  .lst-kix_list_5-8 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_4-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-1 {
    list-style-type: none;
  }
  .lst-kix_list_5-4 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_4-4 {
    list-style-type: none;
  }
  .lst-kix_list_5-5 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_4-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-3 {
    list-style-type: none;
  }
  .lst-kix_list_6-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_6-3 > li:before {
    content: '\0025cf  ';
    font-size: 8px;
    transform: translateY(-2px);
  }
  .lst-kix_list_6-0 > li:before {
    content: '\0025cf  ';
    font-size: 8px;
    transform: translateY(-2px);
  }
  .lst-kix_list_6-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_6-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_6-8 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_6-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_6-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_6-6 > li:before {
    content: '\0025cf  ';
    font-size: 8px;
    transform: translateY(-2px);
  }
  .lst-kix_list_2-6 > li:before {
    content: '\0025cf  ';
    font-size: 8px;
    transform: translateY(-2px);
  }
  .lst-kix_list_2-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_2-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_2-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_2-8 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_3-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-4 {
    list-style-type: none;
  }
  .lst-kix_list_4-0 > li:before {
    content: '\0025cf  ';
    font-size: 8px;
    transform: translateY(-2px);
  }
  .lst-kix_list_4-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_4-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_4-3 > li:before {
    content: '\0025cf  ';
    font-size: 8px;
    transform: translateY(-2px);
  }
  .lst-kix_list_4-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_4-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_4-6 > li:before {
    content: '\0025cf  ';
    font-size: 8px;
    transform: translateY(-2px);
  }
  ul.lst-kix_list_6-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-2 {
    list-style-type: none;
  }
  .lst-kix_list_1-0 > li:before {
    content: '\0025cf  ';
    font-size: 8px;
    transform: translateY(-2px);
  }
  ul.lst-kix_list_2-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-3 {
    list-style-type: none;
  }
  .lst-kix_list_1-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_1-2 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_2-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-5 {
    list-style-type: none;
  }
  .lst-kix_list_1-3 > li:before {
    content: '\0025cf  ';
    font-size: 8px;
    transform: translateY(-2px);
  }
  .lst-kix_list_1-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_1-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_1-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_1-6 > li:before {
    content: '\0025cf  ';
    font-size: 8px;
    transform: translateY(-2px);
  }
  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }
  .lst-kix_list_2-0 > li:before {
    content: '\0025cf  ';
    font-size: 8px;
    transform: translateY(-2px);
  }
  .lst-kix_list_2-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_1-8 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_2-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_2-3 > li:before {
    content: '\0025cf  ';
    font-size: 8px;
    transform: translateY(-2px);
  }
  ol {
    margin: 0;
    padding: 0;
  }
  table td,
  table th {
    padding: 0;
  }
  .c18 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 227.8pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c10 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 239.7pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c7 {
    -webkit-text-decoration-skip: none;
    color: #000000;
    font-weight: 700;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 1rem;
    font-style: normal;
    margin-bottom: 8px;
    display: inline-block;
  }
  .c0 {
    color: #000000;
    font-weight: 300;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 1rem;
    font-style: normal;
  }
  .c22 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: center;
  }
  .c1 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c14 {
    -webkit-text-decoration-skip: none;
    color: #0000ff;
    text-decoration: underline;
    text-decoration-skip-ink: none;
  }
  .c15 {
    margin-left: -5.4pt;
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto;
  }
  .c16 {
    -webkit-text-decoration-skip: none;
    text-decoration: underline;
    text-decoration-skip-ink: none;
  }
  .c20 {
    font-weight: 700;
    font-size: 12pt;
  }
  .c2 {
    font-size: 1rem;
    font-weight: 300;
  }
  .c19 {
    text-decoration: none;
    vertical-align: baseline;
    font-style: italic;
  }
  .c24 {
    font-weight: 300;
    font-size: 12pt;
  }
  .c11 {
    font-size: 1rem;
    font-weight: 700;
  }
  .c12 {
    color: inherit;
    text-decoration: inherit;
  }
  .c6 {
    margin-left: 36pt;
    padding-left: 0pt;
  }
  .c21 {
    vertical-align: baseline;
    font-style: normal;
  }
  .c17 {
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt;
  }
  .c4 {
    padding: 0;
    margin: 0;
  }
  .c8 {
    color: #000000;
  }
  .c9 {
    margin-left: 36pt;
  }
  .c13 {
    height: 0pt;
  }
  .c23 {
    text-decoration: none;
  }
  .c5 {
    height: 12pt;
  }
  .c3 {
    // background-color: #ffffff;
  }
  .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;

    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;

    line-height: 1;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  li {
    color: #000000;
    font-size: 12pt;
  }
  p {
    margin: 0;
    color: #000000;
    font-size: 12pt;
  }
  h1:not(.font-serif) {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 6pt;

    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h2 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 0pt;

    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;

    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;

    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;

    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 1rem;
    padding-bottom: 2pt;

    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
}
